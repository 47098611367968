/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, } from '@angular/common/http';
import { StoreDbService, OBJECTNAME, KamliApp, LlaUser, AUTHSTATUS} from './firebase-service';
import { UtilsService } from './utils.service';
import { AlertController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { regexMobileNo } from './service-service';

export const firebaseConfig = {
  apiKey: 'AIzaSyAFIiBNkBda_tNdkppBmdzCzZhizmFOgKc',
  authDomain: 'backend-prod-e4d4e.firebaseapp.com',
  databaseURL: 'https://backend-prod-e4d4e.firebaseio.com',
  projectId: 'backend-prod-e4d4e',
  storageBucke: 'backend-prod-e4d4e.appspot.com',
  messagingSenderId: '981006637106'
};

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  public userInfo: LlaUser
  public currentUser;
  public allUsers: LlaUser[];
  public allUsersO: BehaviorSubject<LlaUser[]> = new BehaviorSubject(null);
  public confirmationResult;
  public firebaseauth;
  public recaptchaVerifier;

  constructor(
    public http: HttpClient,
    public storeDbSvc: StoreDbService,
    public utilSvc: UtilsService,
    private alertController: AlertController,
  ) { }

  initSmsOtp() {
    firebase.initializeApp(firebaseConfig);
    this.firebaseauth = firebase.auth;
    this.recaptchaVerifier = new this.firebaseauth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response) => {

      },
      'expired-callback': () => {
      }
    });
  }

  createSms(phoneNumber: string) {
    return new Promise(async (resolve, reject) => {
      if (this.recaptchaVerifier && phoneNumber) {
        this.firebaseauth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then(
          async success => {
            this.confirmationResult = success;
            console.log('success=', success);
            resolve(success);
          },
          error => {
            console.log('error=', error);
            reject(error);
          }
        );
      } else {
        console.log('error phoneNumber=', phoneNumber);
        reject(false);
      }
    });
  }



  // Button event after the nmber is entered and button is clicked
  checkMobilePhone(phoneNumber: string) {
    return new Promise(async (resolve, reject) => {
      if (this.recaptchaVerifier && phoneNumber) {
        this.firebaseauth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then(
          async success => {
            this.confirmationResult = success;
            const result = await this.OtpVerification1();
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      } else {
        reject(false);
      }
    });
  }

  async showSuccess() {
    const alert = await this.alertController.create({
      header: 'Success',
      buttons: [
        {
          text: 'Ok',
          handler: (res) => {
            alert.dismiss();
          }
        }
      ]
    });
    alert.present();
  }

  OtpVerification(otp: string) {
    return new Promise(async (resolve, reject) => {
      if (this.confirmationResult) {
        this.confirmationResult.confirm(otp).then(
          userData => {
            resolve(true);
          },
          error => {
            console.log('error=', error);
            resolve(false);
          }
        );
      } else {
        reject(1);
      }
    });
  }

  OtpVerification1() {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Enter OTP',
        backdropDismiss: false,
        inputs: [
          {
            name: 'otp',
            type: 'text',
            placeholder: 'Enter your otp',
          }
        ],
        buttons: [{
          text: 'Enter',
          handler: (res) => {
            this.confirmationResult.confirm(res.otp).then(
              userData => {
                resolve(true);
              },
              error => {
                resolve(false);
              }
            );
          }
        }
        ]
      });
      await alert.present();

    });
  }

  createUser(otpUserId: string, app: KamliApp) {
    return new Promise((resolve, reject) => {
      const body = {
        otpUserId,
        app
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      // tslint:disable-next-line: deprecation
      this.http.post(this.utilSvc.backendURL + 'user/createUserPre', body, httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        });
    });
  }

  deleteUser(adnUser: LlaUser) {
    return new Promise((resolve, reject) => {
      const promises = [];
      const body = {
        uid: adnUser.adnUserId,
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      promises.push(new Promise((resolve1, reject1) => {
        // tslint:disable-next-line: deprecation
        this.http.post(this.utilSvc.backendURL + 'user/deleteUser', body, httpOptions).subscribe(
          result => {
            resolve1(result);
          },
          error => {
            reject1(error);
          });
      }));

      promises.push(new Promise((resolve1, reject1) => {
        this.storeDbSvc.removeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.llausers, adnUser.adnUserId).then(
          () => {
            resolve1(adnUser);
          },
          (error) => reject1(error)
        );
      }));

      Promise.all(promises).then(
        data => resolve(data),
        error => reject(error)
      );
    });
  }

  authUserU(identif: string, password1: string, emailNotVerified?: boolean) {
    return new Promise(async (resolve, reject) => {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
      const phoneRegex = regexMobileNo;
      if (identif && password1) {
        const matche = identif.match(emailRegex);
        if (matche) {
          this.authUser(identif, password1, emailNotVerified).then(
            data1 => resolve(data1),
            error1 => reject(error1)
          );
        } else {
          if (!this.allUsers) {
            let temp;
            try {
              temp = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.llausers);
            } catch (e) { }
            if (temp) {
              this.allUsers = this.utilSvc.objectToArray(temp);
            }
          }
          const matchm = identif.match(phoneRegex);
          if (matchm) {
            const tempm = this.allUsers && this.allUsers.find(u => u.phonenumber === identif.toLowerCase().trim());
            if (tempm) {
              this.authUser(tempm.email, password1, emailNotVerified).then(
                data => resolve(data),
                error => reject(error)
              );
            } else {
              reject([-100, 'unknown user']);
            }
          } else {
            const tempm = this.allUsers && this.allUsers.find(u => u.accountname === identif.toLowerCase().trim());
            if (tempm) {
              this.authUser(tempm.email, password1, emailNotVerified).then(
                data => resolve(data),
                error => reject(error)
              );
            } else {
              reject([-100, 'unknown user']);
            }
          }
        }
      } else {
        reject([-100, 'missing identif and password']);
      }
    });

  }


  authUser(email: string, password1: string, emailNotVerified?: boolean) {
    const password = password1;
    const maf = this.utilSvc.mauth;

    return new Promise((resolve, reject) => {
      maf.signInWithEmailAndPassword(email.toLowerCase(), password).then(
        (success) => {
          const user = success.user;
          if (user.emailVerified || emailNotVerified) {
            resolve([AUTHSTATUS.SUCCESS, user]);
          }
          else {
            reject([AUTHSTATUS.EMAILNOTVERIFIED, 'Login Failed! email not verified']);
          }
        },
        error => {
          reject([AUTHSTATUS.UNKNOWNERROR, error]);
        })
        .catch((error) => {
          reject([AUTHSTATUS.UNKNOWNERROR, error]);
        });
    });
  }

  logout() {
    const maf = this.utilSvc.mauth;
    return new Promise((resolve, reject) => {
      maf.signOut().then(
        (success) => {
          resolve(success);
        },
        error => {
          reject(error);
        })
        .catch((error) => {
          reject(error);
        });
    });

  }

  resetPwdUser(email: string) {
    const maf = this.utilSvc.mauth;
    return new Promise((resolve, reject) => {
      maf.sendPasswordResetEmail(email)
        .then(
          () => {
            resolve(1);
          },
          (error) => {
            reject(error);
          })
        .catch(
          (error) => {
            reject(error);
          });
    });
  }

  checkUserEmail(email1: string) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('currentEmail', encodeURIComponent(email1));
      // tslint:disable-next-line: deprecation
      this.http.get(this.utilSvc.backendURL + 'user/checkEmail', { params: params1, observe: 'body' }).subscribe(
        account => {
          resolve(account);
        },
        error => {
          reject(error);
        });
    });
  }

  getUserByEmail(email: string) {
    const mdb = this.utilSvc.mdb;
    const objectName = OBJECTNAME.llausers;
    const storeId = this.utilSvc.backendFBstoreId;
    let users = [] as LlaUser[];

    return new Promise((resolve, reject) => {
      this.storeDbSvc.getObject(storeId, this.utilSvc.mdb, OBJECTNAME.llausers).then(
        data => {
          users = this.utilSvc.objectToArray(data);
          const temp = users.find(u => u.email === email);
          resolve(temp);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  sendVerificationEmail(adnUser: LlaUser) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('name', adnUser.accountname)
        .set('language', this.utilSvc.language)
        .set('email', encodeURIComponent(adnUser.email));
      // tslint:disable-next-line: deprecation
      this.http.get(this.utilSvc.backendURL + 'mailer/verificationEmail', { params: params1, observe: 'body' }).subscribe(
        account => {
          resolve(account);
        },
        error => {
          reject(error);
        });
    });
  }

  updatePwd(adnUser: LlaUser, oldPwd1: string, newPwd1: string): Promise<any> {
    return new Promise((resolve, reject) => {

      this.authUser(adnUser.email, oldPwd1).then(
        data => {
          const body = {
            email: adnUser.email,
            newpassword: newPwd1,
          };
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          };
          // tslint:disable-next-line: deprecation
          this.http.post(this.utilSvc.backendURL + 'user/updatePwd', body, httpOptions).subscribe(
            result => {
              resolve(result);
            },
            error => {
              reject(error);
            });
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getUser(adnUserId: string): Promise<LlaUser> {
    const storeId = this.utilSvc.backendFBstoreId;

    return new Promise(async (resolve, reject) => {
      if (!adnUserId) {
        resolve(undefined);
      }
      else {
        this.storeDbSvc.getObject(storeId, this.utilSvc.mdb, OBJECTNAME.llausers, adnUserId).then(
          async data => {
            this.userInfo = data as LlaUser;
            if (this.userInfo) {
              resolve(this.userInfo);
            } else {
              const llauser = await this.storeDbSvc.getObject(storeId, this.utilSvc.mdb, OBJECTNAME.llausers, adnUserId);
              resolve(llauser as LlaUser);
            }
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }



}

