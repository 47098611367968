import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../layout.service';
import { UtilsService, KamliUser } from 'godigital-lib';
import { Subscription, } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    public router: Router,
    public layoutSvc: LayoutService,
    public utilSvc: UtilsService,
    public translateSvc: TranslateService,

  ) { }

  ngOnInit() {
    this.layoutSvc.createForm();
  }

  processMonitoring() {
    this.layoutSvc.monitorData = this.layoutSvc.monitoringForm.value.data;
    this.layoutSvc.monitorSipRegister = this.layoutSvc.monitoringForm.value.sipregister;
    this.layoutSvc.monitorSipCall = this.layoutSvc.monitoringForm.value.sipcall;
  }

}
