/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LlaUser } from 'godigital-lib';
import { LocalUtilsService } from '../services/services.service';
import { ServicesService, } from 'godigital-lib';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public activeTab = 0;
  public mactiveTab = 0;
  public monitoringForm: FormGroup;

  constructor(
    public mainSvc: ServicesService,
    public localUtilsSvc: LocalUtilsService,
    public fb: FormBuilder,
    public router: Router,
  ) { }

  get adnBusinessUser() {
    return this.localUtilsSvc.adnBusinessUser;
  }
  set adnBusinessUser(value: LlaUser) {
    this.localUtilsSvc.adnBusinessUser = value;
  }

  get errorMessage() {
    return this.localUtilsSvc.errorMessage;
  }
  set errorMessage(value) {
    this.localUtilsSvc.errorMessage = value;
  }

  get modeUser() {
    return this.localUtilsSvc.modeUser;
  }
  set modeUser(value) {
    this.localUtilsSvc.modeUser = value;
  }

  get version() {
    return this.mainSvc.version;
  }
  set version(value) {
    this.mainSvc.version = value;
  }

  get monitorData() {
    return this.localUtilsSvc.monitorData;
  }
  set monitorData(value) {
    this.localUtilsSvc.monitorData = value;
  }

  get monitorSipCall() {
    return this.localUtilsSvc.monitorSipCall;
  }
  set monitorSipCall(value) {
    this.localUtilsSvc.monitorSipCall = value;
  }

  get monitorSipRegister() {
    return this.localUtilsSvc.monitorSipRegister;
  }
  set monitorSipRegister(value) {
    this.localUtilsSvc.monitorSipRegister = value;
  }

  get b2bcustomers() {
    return this.mainSvc.b2bcustomers;
  }
  set b2bcustomers(value) {
    this.mainSvc.b2bcustomers = value;
  }

  get currentCustomer() {
    return this.localUtilsSvc.currentCustomer;
  }
  set currentCustomer(value) {
    this.localUtilsSvc.currentCustomer = value;
  }

  get currentCustomersite() {
    return this.localUtilsSvc.currentCustomersite;
  }
  set currentCustomersite(value) {
    this.localUtilsSvc.currentCustomersite = value;
  }

  logout() {
  }

  createForm() {
    this.monitoringForm = this.fb.group({
      data: [true],
      sipregister: [true],
      sipcall: [true],
    });

  }



}
