import {
  Component, OnInit, Pipe, PipeTransform
} from '@angular/core';
import { filter } from 'rxjs/operators';
import {
  HttpClient,
} from '@angular/common/http';
import { UtilsService, dayInMilliseconds } from './utils.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'orderstatus'
})

export class OrderStatus implements PipeTransform {
  transform(objects: any[], status, paydineOrder = true, paycollectOrder = true, paydeliverOrder = true, fake): any[] {
    if (objects) {
      return objects.filter(object => {
        fake++;
        let a: boolean;
        let b: boolean;
        let c: boolean;
        let d: boolean;
        a = paydineOrder ? String(object.deliveryDetails.type).toLowerCase() === 'paydine' : false;
        b = paycollectOrder ? String(object.deliveryDetails.type).toLowerCase() === 'paycollect' : false;
        c = paydeliverOrder ? String(object.deliveryDetails.type).toLowerCase() === 'paydeliver' : false;
        d = String(object.status).toLowerCase().toLowerCase() === String(status).toLowerCase();
        return ((a || b || c) && (d));
      });
    }
  }
}

@Pipe({
  name: 'productdeliverytype2'
})

export class ProductDeliveryType implements PipeTransform {
  transform(objects: any[], deliveryType = 'payDine', fake?): any[] {
    if (objects) {
      let paydineProduct = false;
      let paycollectProduct = false;
      let paydeliverProduct = false;
      switch (deliveryType) {
        case 'payCollect':
          paycollectProduct = true;
          break;
        case 'payDeliver':
          paydeliverProduct = true;
          break;
        case 'payDine':
          paydineProduct = true;
          break;
      }
      return objects.filter(object => {
        if (!fake) {
          fake = 0;
        }
        fake++;
        let a;
        let b;
        let c;
        a = paydineProduct ? object.deliveryMethod.payDine : false;
        b = paycollectProduct ? object.deliveryMethod.payCollect : false;
        c = paydeliverProduct ? object.deliveryMethod.payDeliver : false;
        return (a || b || c);
      });
    }
  }
}

@Pipe({
  name: 'deliveryInfo'
})

export class OrderDeliveryInfo implements PipeTransform {
  transform(object: any, info, paydineFiler = true, paycollectFiler = true, paydeliverFiler = true): any {
    let deliveryTypeString = '';
    let deliveryTime: string;
    let deliveryDate: string;
    let returnInfo = '';
    let totalOrder = 0;
    let totalcartItems = 0;
    const totalVAT = { 5: 0, 10: 0, 20: 0 };
    let grandTotalVAT = 0;

    if (object) {
      if ((object.paydine) && (paydineFiler)) {
        deliveryTypeString = 'Pay&Dine';
        deliveryTime = object.deliveryDetails.paydine.time;
        deliveryDate = object.deliveryDetails.paydine.date;
        let i;
        for (i = 0; i < object.paydine.length; i++) {
          totalOrder = totalOrder + object.paydine[i].itemTotalPrice;
          const vatType = object.paydine[i].item.vat.type;
          totalVAT[vatType] = totalVAT[vatType] + object.paydine[i].itemTotalPrice * (1 - 1 / (1 + vatType / 100));
        }
        totalcartItems = totalcartItems + object.paydine.length;

      }
      if ((object.paycollect) && (paycollectFiler)) {
        if (deliveryTypeString.length > 0) {
          deliveryTypeString = deliveryTypeString + ' + ';
        }
        deliveryTypeString = deliveryTypeString + 'Pay&Collect';
        deliveryTime = object.deliveryDetails.paycollect.time;
        deliveryDate = object.deliveryDetails.paycollect.date;
        let i;
        for (i = 0; i < object.paycollect.length; i++) {
          totalOrder = totalOrder + object.paycollect[i].itemTotalPrice;
          const vatType = object.paycollect[i].item.vat.type;
          totalVAT[vatType] = totalVAT[vatType] + object.paycollect[i].itemTotalPrice * (1 - 1 / (1 + vatType / 100));
        }
        totalcartItems = totalcartItems + object.paycollect.length;
      }
      if ((object.paydeliver) && (paydeliverFiler)) {
        if (deliveryTypeString.length > 0) {
          deliveryTypeString = deliveryTypeString + ' + ';
        }
        deliveryTypeString = deliveryTypeString + 'Pay&Deliver';
        deliveryTime = object.deliveryDetails.paydeliver.time;
        deliveryDate = object.deliveryDetails.paydeliver.date;
        let i;
        for (i = 0; i < object.paydeliver.length; i++) {
          totalOrder = totalOrder + object.paydeliver[i].itemTotalPrice;
          const vatType = object.paydeliver[i].item.vat.type;
          totalVAT[vatType] = totalVAT[vatType] + object.paydeliver[i].itemTotalPrice * (1 - 1 / (1 + vatType / 100));
        }
        totalcartItems = totalcartItems + object.paydeliver.length;
      }
      switch (info) {
        case 'deliveryType':
          returnInfo = deliveryTypeString;
          break;
        case 'date':
          returnInfo = deliveryDate;
          break;
        case 'time':
          returnInfo = deliveryTime;
          break;
        case 'total':
          totalOrder = Math.round(totalOrder * 100) / 100;
          returnInfo = String(totalOrder);
          break;

        case 'totalCartItems':
          totalcartItems = Math.round(totalcartItems * 100) / 100;
          returnInfo = String(totalcartItems);
          break;
        case 'totalVAT5':
          totalVAT[5] = Math.round(totalVAT[5] * 100) / 100;
          returnInfo = String(totalVAT[5]);
          break;
        case 'totalVAT10':
          totalVAT[10] = Math.round(totalVAT[10] * 100) / 100;
          returnInfo = String(totalVAT[10]);
          break;
        case 'totalVAT20':
          totalVAT[20] = Math.round(totalVAT[20] * 100) / 100;
          returnInfo = String(totalVAT[20]);
          break;
        case 'grandTotalVAT':
          grandTotalVAT = Math.round((totalVAT[5] + totalVAT[10] + totalVAT[20]) * 100) / 100;
          returnInfo = String(grandTotalVAT);
          break;
        case 'totalHT':
          let totalHT = totalOrder - totalVAT[5] - totalVAT[10] - totalVAT[20];
          totalHT = Math.round(totalHT * 100) / 100;
          returnInfo = String(totalHT);
          break;
        default:
          returnInfo = '';
      }
    }
    return returnInfo;
  }
}


@Pipe({
  name: 'filterGenericN',
  pure: false
})

export class FilterGenericN implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?, negativeValue?): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if (negativeValue) {
        if (negativeValue !== '-1') {
          temp = objects.filter(i => {
            if (i != null) {
              return String(i[filterField]) === String(filterValue);
            }
            else {
              return false;
            }
          });
        }
        else {
          temp = objects.filter(i => {
            if (i != null) {
              return String(i[filterField]) !== String(filterValue);
            }
            else {
              return false;
            }
          });
        }
      }
      else {
        temp = objects.filter(i => String(i[filterField]) === String(filterValue));
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'countGenericN',
  pure: false
})

export class CountGenericN implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?, negativeValue?): number {
    let temp: any[] = [];
    if (objects) {
      if (fake) {
        fake++;
      }
      if (negativeValue) {
        if (negativeValue !== '-1') {
          temp = objects.filter(i => {
            if (i != null) {
              return i[filterField] === filterValue;
            }
            else {
              return false;
            }
          });
        }
        else {
          temp = objects.filter(i => {
            if (i != null) {
              return i[filterField] !== filterValue;
            }
            else {
              return false;
            }
          });
        }
      }
      else {
        temp = objects.filter(i => i[filterField] === filterValue);
      }
      if (!temp) {
        temp = [];
      }
    }
    return temp.length;
  }
}


@Pipe({
  name: 'filterGenericNS',
  pure: false
})

export class FilterGenericNS implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?, negativeValue?): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if (filterValue && (filterValue !== -1)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField]) {
              return String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'countGenericS',
  pure: false
})

export class CountGenericS implements PipeTransform {
  transform(objects: any[], filterField, filterValue: string, reverse?, fake?,): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if ((filterValue) && (filterValue.length > 0)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField] && filterValue) {
              if (reverse) {
                if (!reverse) {
                  return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) === 0;
                }
                else {
                  return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) !== 0;
                }
              }
              else {
                return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) === 0;
              }
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp.length;
    }
  }
}


@Pipe({
  name: 'filterGenericS',
  pure: false
})

export class FilterGenericS implements PipeTransform {
  transform(objects: any[], filterField, filterValue: string, reverse?, fake?,): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if ((filterValue) && (filterValue.length > 0)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField] && filterValue) {
              if (reverse) {
                if (!reverse) {
                  return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) === 0;
                }
                else {
                  return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) !== 0;
                }
              }
              else {
                return String(i[filterField]).toLowerCase().search(String(filterValue).toLowerCase()) === 0;
              }
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'countGenericPS',
  pure: false
})

export class CountGenericPS implements PipeTransform {
  transform(objects: any[], filterField, filterValue, reverse?, fake?,): number {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if ((String(filterValue)) && (String(filterValue).length > 0)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField]) {
              if (reverse) {
                if (!reverse) {
                  return String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
                }
                else {
                  return !String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
                }
              }
              else {
                return String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
              }
            }
          }
          else {
            return false;
          }
        });
      } else {
        temp = objects;
      }
      return temp.length;
    }
  }
}


@Pipe({
  name: 'filterGenericPS',
  pure: false
})

export class FilterGenericPS implements PipeTransform {
  transform(objects: any[], filterField, filterValue, reverse?, fake?,): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if ((String(filterValue)) && (String(filterValue).length > 0)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField]) {
              if (reverse) {
                if (!reverse) {
                  return String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
                }
                else {
                  return !String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
                }
              }
              else {
                return String(i[filterField]).toLowerCase().includes(String(filterValue).toLowerCase());
              }
            }
          }
          else {
            return false;
          }
        });
      } else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'filterGenericPSO',
  pure: false
})

export class FilterGenericPSO implements PipeTransform {
  transform(objects: any[], filterField1, filterValue1, filterField2, filterValue2): any[] {
    if (objects) {
      let temp;
      if ((filterValue1) && (filterValue2)) {
        temp = objects.filter(i => {
          if (i) {
            if (i[filterField1] && i[filterField2]) {
              return (String(i[filterField1]).toLowerCase().includes(String(filterValue1).toLowerCase()) ||
                String(i[filterField2]).toLowerCase().includes(String(filterValue2).toLowerCase()));
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'filterGenericPSA',
  pure: false
})

export class FilterGenericPSA implements PipeTransform {
  transform(objects: any[], filterField1, filterValue1, filterField2, filterValue2): any[] {
    if (objects) {
      let temp;
      if ((filterValue1) && (filterValue2)) {
        temp = objects.filter(i => {
          if (i) {
            if (i[filterField1] && i[filterField2]) {
              return (String(i[filterField1]).toLowerCase().includes(String(filterValue1).toLowerCase()) &&
                String(i[filterField2]).toLowerCase().includes(String(filterValue2).toLowerCase()));
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'filterGenericIS',
  pure: false
})

export class FilterGenericIS implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?,): any[] {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if ((filterValue !== -1) && (filterValue) && (filterValue != null)) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField]) {
              return i[filterField].toLowerCase().includes(String(filterValue).toLowerCase());
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}

@Pipe({
  name: 'filterGenericA',
  pure: false
})

export class FilterGenericA implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?,): any[] {
    if (objects) {
      let temp = [];
      if (fake) {
        fake++;
      }
      if ((filterValue !== -1) && (filterValue) && (filterValue != null)) {
        if (filterValue.length === 0) {
          temp = objects;
        }
        else {
          if (objects) {
            for (const obj of objects) {
              let found = false;
              for (let i = 0; i < obj[filterField].length && !found; i++) {
                for (let j = 0; j < filterValue.length && !found; j++) {
                  if (obj != null) {
                    if (String(obj[filterField][i]).toLowerCase() === String(filterValue[j]).toLowerCase()) {
                      temp.push(obj);
                      found = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      else {
        temp = objects;
      }
      return temp;
    }
  }
}


@Pipe({
  name: 'filterGenericB',
  pure: false
})

export class FilterGenericB implements PipeTransform {
  transform(objects: any[], filterField, filterValue?, fake?,): any[] {
    if (objects) {
      let temp;
      if (fake !== undefined) {
        fake++;
      }
      temp = objects.filter(i => {
        let temp2 = false;
        if (filterValue === undefined) {
          filterValue = true;
        }
        if (i != null) {
          if (i[filterField] !== undefined) {
            temp2 = i[filterField] === filterValue;
          }
          else {
            temp2 = true;
          }
        }
        return temp2;
      });
      return temp;
    }
  }
}



@Pipe({
  name: 'countGenericB',
  pure: false
})

export class CountGenericB implements PipeTransform {
  transform(objects: any[], filterField, filterValue, fake?,): number {
    if (objects) {
      let temp;
      if (fake) {
        fake++;
      }
      if (filterValue === undefined) {
        filterValue = true;
      }
      if (filterValue !== undefined) {
        temp = objects.filter(i => {
          if (i != null) {
            if (i[filterField]) {
              return i[filterField];
            }
          }
          else {
            return false;
          }
        });
      }
      else {
        temp = objects;
      }
      return temp.length;
    }
  }
}




@Pipe({
  name: 'parentCategory'
})

export class ParentCategory implements PipeTransform {
  transform(objects: any[], parentCategory): any[] {
    if (objects) {
      const temp = objects.filter(i => i.parentCategory === parentCategory && i.visible);
      return temp;
    }
  }
}

@Pipe({
  name: 'translateAuto'
})

export class TranslateAuto implements PipeTransform {
  constructor(public http: HttpClient, public utilsSvc: UtilsService) {

  }

  transform(text: string, fake, languageo?) {
    let language;
    if (!languageo) {
      language = 'en-' + this.utilsSvc.language;
    }
    else {
      language = languageo + '-' + this.utilsSvc.language;
    }
    return this.utilsSvc.translate(text, language).then(data => {
      return data;
    });
  }
}


@Pipe({
  name: 'addComponent'
})

export class AddComponent implements PipeTransform {
  transform(text: string, componentName: string) {
    return componentName + '.' + text;
  }


}


@Pipe({
  name: 'adnDate'
})

export class AdnDate implements PipeTransform {
  transform(dateTime: number) {
    if (!isNaN(dateTime)) {
      return new Date(dateTime);
    } else {
      return new Date();
    }
  }
}

@Pipe({
  name: 'totalCalc'
})

export class TotalCalc implements PipeTransform {
  transform(tableCal: any[], arg1: any, arg2?: any) {
    let total = 0;
    let temp = 0;
    if (tableCal) {
      for (const tableC of tableCal) {
        temp = 0;
        if (tableC[arg1]) {
          if (arg2) {
            if (tableC[arg1][arg2]) {
              temp = tableC[arg1][arg2];
            }
          }
          else {
            temp = tableC[arg1];
          }
        }
        total += temp;
      }
    }
    return total;
  }
}

@Pipe({
  name: 'campaignAnalyticsTotal'
})
export class CampaignAnalyticsTotal implements PipeTransform {
  transform(campaignAnalyticsA: any[], counter: string, storeId?: string, deviceId?: string, day?: string) {
    let result = 0;
    let campaignAnalyticsAT: any[];

    if (campaignAnalyticsA) {
      if (storeId) {
        const storeIdString = 'storeId';
        campaignAnalyticsAT = campaignAnalyticsA.filter(ca => ca[storeIdString] === storeId);
        campaignAnalyticsA = campaignAnalyticsAT;
      }
      if (day) {
        const dayString = 'day';
        campaignAnalyticsAT = campaignAnalyticsA.filter(ca => ca[dayString] === day);
        campaignAnalyticsA = campaignAnalyticsAT;
      }
      if (deviceId) {
        const deviceIdString = 'deviceId';
        campaignAnalyticsAT = campaignAnalyticsA.filter(ca => ca[deviceIdString] === deviceId);
        campaignAnalyticsA = campaignAnalyticsAT;
      }
      if (campaignAnalyticsA) {
        for (const campaignAnalyticsA1 of campaignAnalyticsA) {
          if (campaignAnalyticsA1[counter]) {
            result += campaignAnalyticsA1[counter];
          }
        }
      }
    }
    return result;
  }
}


@Pipe({
  name: 'stringRegex'
})

export class StringRegex implements PipeTransform {
  transform(initialString: string) {
    const regEx = /([\w%\sèéàô\-\.]+)\/([\w%èéàô\s\-\.]+$)/;

    const temp = regEx.exec(initialString);
    if (temp && temp != null) {
      return temp[2];
    } else {
      return '';
    }
  }
}


@Pipe({ name: 'safe' })

export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'wordcount'
})
export class WordcountPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.trim().split(/\s+/).length;
  }
}
