/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
  UsersService, LlaUser, ServicesService, UtilsService, Llamarket, B2brevenu, B2bcustomersite, MailDetailsI, } from 'godigital-lib';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

declare let what3words: any;
declare let $: any;

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

@Injectable({
  providedIn: 'root'
})
export class LocalUtilsService {
  public adnBusinessUser: LlaUser;
  public errorMessage = {
    title: '',
    description: '',
    details: ''
  };
  public platform;

  public opcoForm: FormGroup;
  public showModaltwoButtonsO: BehaviorSubject<any> = new BehaviorSubject(null);
  public showModaltwoButtonsSubscribtion: Subscription;

  public language = 'en';

  public modeUser = this.utilsSvc.getVariable('modeUser') === undefined ? 1 : Number(this.utilsSvc.getVariable('modeUser'));
  public accountCreated = false;
  public regexPhone = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

  public currentMarket: Llamarket;
  public currentCustomer: B2brevenu;
  public currentCustomersite: B2bcustomersite;

  public currentMarket1: Llamarket;
  public currentCustomer1: B2brevenu;
  public currentCustomersite1: B2bcustomersite;

  public monitorData = true;
  public monitorSipRegister = true;
  public monitorSipCall = true;

  constructor(
    public http: HttpClient,
    public geolocation: Geolocation,
    public router: Router,
    public mainSvc: ServicesService,
    public usersSvc: UsersService,
    public utilsSvc: UtilsService,
    public spinner: NgxSpinnerService,
  ) {
  }

  showModalNoButton(title: string, description: string) {
    this.errorMessage.title = title;
    this.errorMessage.description = description;
    $('#modal-no-buttons').modal('show');
  }

  showModaltwoButtons(title: string, description: string, details: string) {
    return new Promise(resolve => {
      this.errorMessage.title = title;
      this.errorMessage.description = description;
      this.errorMessage.details = details;
      $('#modal-two-buttons').modal('show');

      this.showModaltwoButtonsSubscribtion = this.getshowModaltwoButtons().subscribe(
        data => {
          if (this.showModaltwoButtonsSubscribtion !== undefined) {
            this.showModaltwoButtonsSubscribtion.unsubscribe();
          }
          if (data != null) {
            resolve(data);
          }
        }
      );
    });
  }

  public getshowModaltwoButtons(): Observable<any> {
    return this.showModaltwoButtonsO.asObservable();
  }
  public setshowModaltwoButtons(value: number) {
    this.showModaltwoButtonsO.next(value);
    this.showModaltwoButtonsO.next(null);
  }

  sendMail(mailDetails: MailDetailsI) {
    return new Promise((resolve, reject) => {
      let params = new HttpParams()
        .set("fromName", encodeURIComponent(mailDetails.fromName))
        .set("fromEmail", encodeURIComponent(mailDetails.from))
        .set("fromPhone", encodeURIComponent(mailDetails.fromPhone))
        .set("toName", encodeURIComponent(mailDetails.toName))
        .set("toEmail", encodeURIComponent(mailDetails.to))
        .set("subject", 'Contact from the website alldigitalnetwork.com.')
        .set("comments", mailDetails.text)
        .set("time", '');

        this.http.get(this.utilsSvc.backendURL + '/mailer/contact', { params: params }).subscribe(
          data => {
            resolve(data)
          },
          error => {
            reject(error)
          })
      
    })
  }
}
