/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import {
    StoreDbService, OBJECTNAME,
} from './firebase-service';
import { UtilsService, dayInMilliseconds } from './utils.service';
import { UsersService, } from './users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NGXLogger } from 'ngx-logger';
import { ScriptLoadingService } from './script-loading.service';

import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { saveAs } from 'file-saver';

export const externalUrlProvider = new InjectionToken(
    'externalUrlRedirectResolver'
);
import { LlaUser, KamliApp } from './firebase-service';

export enum EDITSLIDE {
    CREATIONSLIDE = 0,
    EDITIONSLIDE = 1
}

export const regexUrl = /https?:\/\//;
export const regexUrlImage = /(https?:\/\/.*\.(?:png|jpg|jpeg))/;
export const regexUrlVideo = /(https?:\/\/.*\.(?:mp4|avi))/;
export const regexUrlMedia = /(^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$)|((https?:\/\/.*\.(?:mp4|avi|png|jpg|jpeg)))/;
export const regexMobileNo = /(^(0033|\+33|0)(6|7)(\d{8})$)|(^\+44\d{10}$)|(^\+31\d{8,10}$)|(^\+34(\d{8,10})$)|(^\+41(\d{8,10})$)|(^\+39(\d{8,10})$)/;
export const regexFixedNo = /(^(0033|\+33|0)(1|2|3|4|5|8|9)(\d{8})$)|(^\+44\d{10}$)|(^\+31\d{8,10}$)|(^\+34(\d{8,10})$)|(^\+41(\d{8,10})$)|(^\+39(\d{8,10})$)/;
export const regexEmail = /^[^@]+@[a-z0-9.-]+\.[a-z]{2,3}$/;

export interface Llamarket {
    marketName: string;
    marketId: string;
    opco: string;
    region: number;
    datastatus: string;
    sipresgisterstatus: string;
    sipinvitestatus: string;
    trunkstatus: string;
    datastatusts: string;
    sipresgisterstatusts: string;
    sipinvitestatusts: string;
    trunkstatusts: string;
}

export interface B2brevenu {
    year: string;
    accountName: string;
    customerId: string;
    marketId: string;
    marketName: string;
    mrr: string;
    products: string;
    region: string;
    datastatus: string;
    sipresgisterstatus: string;
    sipinvitestatus: string;
    trunkstatus: string;
    datastatusts: string;
    sipresgisterstatusts: string;
    sipinvitestatusts: string;
    trunkstatusts: string;
    accountManagerName: string;
    accountManagerPhone: string;
    accountManagerEmail: string;
    customerContactName: string;
    customerContactEmail: string;
    customerContactPhone: string;
}

export interface B2bcustomersite {
    siteId: string;
    customerId: string;
    siteaddress: string;
    lines: string;
    since: string;
    mrr: string;
    datastatus: string;
    sipresgisterstatus: string;
    sipinvitestatus: string;
    trunkstatus: string;
    datastatusts: string;
    sipresgisterstatusts: string;
    sipinvitestatusts: string;
    trunkstatusts: string;
    ipaddress: string;
    customerSiteManagerName: string;
    customerSiteManagerEmail: string;
    customerSiteManagerPhone: string;
}

export interface B2bucline {
    lineId: string;
    siteId: string;
    customerId: string;
    model: string;
    sipusername: string;
    macaddress: string;
    dnsname: string;
    ipaddress: string;
    datastatus: string;
    sipresgisterstatus: string;
    sipinvitestatus: string;
    trunkstatus: string;
    datastatusts: string;
    sipresgisterstatusts: string;
    sipinvitestatusts: string;
    trunkstatusts: string;
}

export interface Syslogdata {
    newTS: string;
    initialTS: string;
    ipaddress: string;
    macaddress: string;
    pid: string;
    cpetype: string;
    sipusername: string;
    ucplatform: string;
    messageType: SYSLOGMESSAGETYPE;
}

export enum SYSLOGMESSAGETYPE {
    sipregisterrequest = 'sipregisterrequest',
    sipregistersuccess = 'sipregistersuccess',
    sipregisterfailed = 'sipregisterfailed',
    keepalive = 'keepalive',
    agenttype = 'agenttype',
    sipringing = 'sipringing',
    sipconnect = 'sipconnect',
    siteconnect = 'siteconnect',
}

export enum CPETYPE {
    polycom = 'polycom',
    yealink = 'yealink',
}



@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    public config;
    public backendFbObjects = [
        OBJECTNAME.llausers,
        OBJECTNAME.backendb2brevenu,
        OBJECTNAME.backendb2bucsites,
        OBJECTNAME.backendmarkets,
        OBJECTNAME.backendb2buclines,
        OBJECTNAME.backendb2brevenu1,
        OBJECTNAME.backendb2bucsites1,
        OBJECTNAME.backendmarkets1,
        OBJECTNAME.backendb2buclines1,
    ];
    public backendFbObjectsConnected = [
    ];
    public version;
    public firebaseBSSdata = {};
    public subscriptions = new Subscription();
    public errorMessage = {
        title: '',
        description: '',
        details: ''
    };
    public currentPosition = {
        lat: 0,
        lng: 0
    };
    public adnBusinessUser: LlaUser;
    public adnBusinessUserO: BehaviorSubject<LlaUser> = new BehaviorSubject(null);
    public languageO: BehaviorSubject<string> = new BehaviorSubject(null);
    public b2brevenu: B2brevenu[];
    public b2brevenuO: BehaviorSubject<B2brevenu[]> = new BehaviorSubject(null);
    public b2bcustomersites: B2bcustomersite[];
    public b2bcustomersitesO: BehaviorSubject<B2bcustomersite[]> = new BehaviorSubject(null);
    public b2buclines: B2bucline[];
    public b2buclinesO: BehaviorSubject<B2bucline[]> = new BehaviorSubject(null);
    public llamarkets: Llamarket[];
    public llamarketsO: BehaviorSubject<Llamarket[]> = new BehaviorSubject(null);
    public b2brevenu1: B2brevenu[];
    public b2brevenu1O: BehaviorSubject<B2brevenu[]> = new BehaviorSubject(null);
    public b2bcustomersites1: B2bcustomersite[];
    public b2bcustomersites1O: BehaviorSubject<B2bcustomersite[]> = new BehaviorSubject(null);
    public b2buclines1: B2bucline[];
    public b2buclines1O: BehaviorSubject<B2bucline[]> = new BehaviorSubject(null);
    public llamarkets1: Llamarket[];
    public llamarkets1O: BehaviorSubject<Llamarket[]> = new BehaviorSubject(null);
    public opcos: string[];
    public opcos1: string[];
    public b2bcustomers: B2brevenu[];
    public b2bcustomers1: B2brevenu[];

    constructor(
        public http: HttpClient,
        public router: Router,
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
        public usersSvc: UsersService,
        public spinner: NgxSpinnerService,
        public scriptLoadingSvc: ScriptLoadingService,
        public logger: NGXLogger,
    ) {
    }

    logDS(...args: any[]) {
        let logText = '';
        for (let i = 1; i < args.length; i++) {
            logText = logText + args[i] + ',';
        }
        const userId =
            this.adnBusinessUser
                ? this.adnBusinessUser.adnUserId
                : 'Guest';

        logText =
            args[0] +
            ',' +
            this.utilSvc.appName +
            ',' +
            userId +
            ',' +
            this.currentPosition.lat +
            ',' +
            this.currentPosition.lng +
            ',' +
            logText;
        this.logger.info(logText);
    }

    public readConfigFile(env) {
        return new Promise((resolve, reject) => {
            this.utilSvc.readConfig('./assets/config/adf.json').then(
                data => {
                    this.config = data;
                    const backendURLString = 'backendURL';
                    if (!this.utilSvc.language) {
                        this.utilSvc.language = 'fr';
                    }

                    if (!env || !env.platform) {
                        this.utilSvc.platform = this.config.application?.platform;
                        env = {};
                        env.platform = this.utilSvc.platform;
                    } else {
                        this.utilSvc.platform = env.platform;
                    }
                    this.utilSvc.backendURL = data[env.platform][backendURLString];
                    if (this.config.application) {
                        if (this.config.application.release) {
                            this.version =
                                env.platform + '/' + this.config.application.release;
                        }
                    }
                    if (this.config[this.utilSvc.platform].backendWSUrl) {
                        this.utilSvc.backendWSURL = this.config[this.utilSvc.platform].backendWSUrl;
                    }
                    this.utilSvc.syslogURL = this.config[env.platform].syslogURL;
                    this.utilSvc.syslogPort = this.config[env.platform].syslogPort;
                    this.utilSvc.analyticsUrl = this.config[env.platform].analyticsUrl;
                    this.utilSvc.backendURL = this.config[env.platform].backendURL;

                    this.utilSvc.timeoutdata = this.config[env.platform].timeoutdata;
                    this.utilSvc.timeoutsipregister = this.config[env.platform].timeoutsipregister;
                    this.utilSvc.timeoutsipinvite = this.config[env.platform].timeoutsipinvite;

                    this.utilSvc.appName = this.utilSvc.appName;
                    resolve(this.config);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    public initBEService(env) {
        return new Promise((resolve, reject) => {
            const backendFbConfig = this.config[env.platform].firebaseMasterConfig;
            this.storeDbSvc.initFB(this.utilSvc.backendFBstoreId, backendFbConfig, 'goDigitalBE', true, true,
                this.backendFbObjects, this.storeDbSvc.backendFbRef).then(
                    async () => {
                        const databaseString = 'database';
                        const storageString = 'storage';
                        const authString = 'auth';
                        this.utilSvc.mdb = this.storeDbSvc.backendFbRef[databaseString];
                        this.utilSvc.mst = this.storeDbSvc.backendFbRef[storageString];
                        this.utilSvc.mauth = this.storeDbSvc.backendFbRef[authString];
                        this.backendFbObjects.forEach(fo => {
                            this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, fo);
                        });
                        this.subscribeUsers();

                        this.subscribeb2brevenu();
                        this.subscribeb2bcustomersites();
                        this.subscribeb2buclines();
                        this.subscribeLlamarkets();

                        this.subscribeb2brevenu1();
                        this.subscribeb2bcustomersites1();
                        this.subscribeb2buclines1();
                        this.subscribeLlamarkets1();
                        resolve(1);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    public closeBEService() {
        return new Promise((resolve, _reject) => {
            this.unsubscribeUsers();
            this.unsubscribeb2brevenu();
            this.unsubscribeb2bcustomersites();
            this.unsubscribeb2buclines();
            this.unsubscribeLlamarkets();
            this.unsubscribeb2brevenu1();
            this.unsubscribeb2bcustomersites1();
            this.unsubscribeb2buclines1();
            this.unsubscribeLlamarkets1();

            this.backendFbObjects.forEach(fo => {
                this.storeDbSvc.unsubscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, fo);
            });
            this.utilSvc.mdb = undefined;
            this.utilSvc.mst = undefined;
            this.utilSvc.mauth = undefined;

            this.storeDbSvc.closeFB(
                this.utilSvc.backendFBstoreId,
                this.backendFbObjects,
                this.storeDbSvc.backendFbRef
            );
            resolve(1);
        });
    }

    public getBusinessUserO(): Observable<LlaUser> {
        return this.adnBusinessUserO.asObservable();
    }
    public setBusinessUserO(value: LlaUser) {
        this.adnBusinessUser = value;
        this.adnBusinessUserO.next(value);
    }


    public resetVariables() {

        this.storeDbSvc.storeFbRef = [];
        this.setBusinessUserO(undefined);
    }

    public exportObjects(objects, objectName) {
        const json = JSON.stringify(objects);
        const blob = new Blob([json], { type: 'application/json' });
        saveAs(blob, objectName + '.json');
    }

    public exportString(strings, objectName) {
        const blob = new Blob([strings], { type: 'application/json' });
        saveAs(blob, objectName + '.csv');
    }

    public stringToDate(stringDate: string) {
        const regexDate = /([0-9]{2})([0-9]{2})([0-9]{4})/;
        const dateTemp1 = regexDate.exec(stringDate);
        if (dateTemp1 && dateTemp1 != null && dateTemp1[3]) {
            return new Date(dateTemp1[3] + '-' + dateTemp1[2] + '-' + dateTemp1[1]).getTime();
        } else {
            return 0;
        }
    }

    subscribeUsers() {
        if (this.utilSvc.appName === KamliApp.KAMLIQC) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.llausers].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setUsers(temp);
                }
            );
        }
    }

    unsubscribeUsers() {
        if (this.utilSvc.appName === KamliApp.KAMLIQC) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.llausers);
            this.setUsers(undefined);
        }
    }

    public getUsers(): Observable<LlaUser[]> {
        return this.usersSvc.allUsersO.asObservable();
    }
    public setUsers(value: LlaUser[]) {
        this.usersSvc.allUsers = value;
        this.usersSvc.allUsersO.next(value);
    }

    connectingUser(adnUserId, token: string) {
        return new Promise(resolve => {
            if (adnUserId) {
                let temp: LlaUser;
                this.usersSvc.getUser(adnUserId).then(data => {
                    if (data) {
                        temp = data as LlaUser;
                        if (!this.adnBusinessUser) {
                            this.adnBusinessUser = data as unknown as LlaUser;
                        } else if (this.adnBusinessUser.adnUserId !== temp.adnUserId) {
                            this.adnBusinessUser = data as unknown as LlaUser;
                        }
                        if (this.adnBusinessUser) {
                            this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.llausers);
                            this.storeDbSvc.subscribeObject(
                                this.utilSvc.backendFBstoreId,
                                this.utilSvc.mdb,
                                OBJECTNAME.llausers,
                                adnUserId
                            );
                            //                            this.subscribeUser(adnUserId);
                            this.setLoggedUser(this.adnBusinessUser, token);
                        } else {
                            this.setLoggedUser(undefined, undefined);
                        }
                        resolve(temp);
                    } else {
                        this.setLoggedUser(undefined, undefined);
                        resolve(undefined);
                    }
                });
            } else {
                this.setLoggedUser(undefined, undefined);
                resolve(undefined);
            }
        });
    }

    disconnectingUser(adnUserId) {
        if (adnUserId) {
            this.unsubscribeUser(adnUserId);
            this.setLoggedUser(undefined, undefined);
            this.utilSvc.clearUid();
            this.usersSvc.logout();
        }
    }

    public loginUser(identif: string, password: string, adnUserId: string, token: string) {
        const uidString = 'uid';
        let uid;
        return new Promise(async (resolve, reject) => {
            let temp;
            if (!adnUserId) {
                if (identif && password) {
                    try {
                        temp = await this.usersSvc.authUserU(identif, password, true);
                    } catch (error) {
                        this.errorMessage = {
                            title: 'Authentication error',
                            description:
                                'The password is invalid or the user does not have a kamli account',
                            details: error[1]
                        };
                        this.setLoggedUser(undefined, undefined);
                        reject(error);
                    }
                    if (temp && temp[1]) {
                        uid = temp[1][uidString];
                    }
                } else {
                    this.setLoggedUser(undefined, undefined);
                    reject([-100, 'missing id and/or password']);
                }
            } else {
                uid = adnUserId;
            }
            if (uid) {
                this.usersSvc.getUser(uid).then(
                    async userDetails => {
                        this.adnBusinessUser = userDetails as unknown as LlaUser;
                        const uid = this.adnBusinessUser.adnUserId ? this.adnBusinessUser.adnUserId : this.adnBusinessUser['llaUserId'];
                        try {
                            await this.connectingUser(uid, token);
                        } catch (e) { }
                        resolve(this.adnBusinessUser);
                    },
                    error => {
                        this.errorMessage = {
                            title: 'error getting user information',
                            description: 'please try again later',
                            details: error[1]
                        };
                        reject([-100, error]);
                    }
                );
            } else {
                this.errorMessage = {
                    title: 'Authentication error',
                    description:
                        'The password is invalid or the user does not have a kamli account',
                    details: ''
                };
                this.setLoggedUser(undefined, undefined);
                reject([-100, 'The password is invalid or the user does not have a kamli account']);
            }
        });
    }

    public loginAdmin1(identif: string, password: string, adnUserId: string, storeId1: string, token: string) {
        return new Promise(async (resolvef, rejectf) => {
            let adnUser1: LlaUser;
            let errorString = '';
            if (identif && password) {
                let temp;
                try {
                    temp = await this.usersSvc.authUserU(identif, password).catch(_e => { });
                } catch (e) { }
                if (temp) {
                    const temp2 = temp;
                    const dataAuth = temp2[1];
                    const uidString = 'uid';
                    adnUserId = dataAuth[uidString];
                } else {
                    errorString = 'Authentication error. please check your login user and password';
                    rejectf(errorString);
                }
            }

            if (storeId1 && adnUserId) {
                try {
                    adnUser1 = await this.usersSvc.getUser(adnUserId).catch(_e => { }) as unknown as LlaUser;
                } catch (e) { }
                if (adnUser1) {
                    this.adnBusinessUser = adnUser1 as unknown as LlaUser;
                } else {
                    errorString = 'Authentication error. user is not a corporate client 2/2';
                    rejectf(errorString);
                }
            } else {
                errorString = 'Authentication error. user unknown on the kamli network';
                rejectf(errorString);
            }
            if (adnUser1 && this.utilSvc.appName !== KamliApp.KAMLIQC) {
                this.spinner.show();
            } else {
                errorString = 'Authentication error. this user cannot have access to this store account';
                rejectf(errorString);
            }
        });
    }

    public readInitialUrl(initialUrl) {
        const regexwindow = /(http:\/\/|https:\/\/)[^\/]+\/(.+)/;
        if (initialUrl && initialUrl.href) {
            const temp = initialUrl.href.match(regexwindow);
            if (temp && temp[2]) {
                return temp[2]
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public subscribeUser(_adnUserId: string) {
        if (this.firebaseBSSdata[OBJECTNAME.llausers]) {
            this.firebaseBSSdata[OBJECTNAME.llausers].unsubscribe();
        }
        this.firebaseBSSdata[OBJECTNAME.llausers] =
            this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.llausers].subscribe(
                data => {
                    const temp = data ? data[0] : undefined;
                    this.setLoggedUser(temp, undefined);
                },
                error => console.log(error)
            ) as Subscription;
    }

    public unsubscribeUser(adnUserId: string) {
        this.storeDbSvc.unsubscribeObject(
            this.utilSvc.backendFBstoreId,
            this.utilSvc.mdb,
            OBJECTNAME.llausers,
            adnUserId
        );
        if (this.firebaseBSSdata[OBJECTNAME.llausers]) {
            this.firebaseBSSdata[OBJECTNAME.llausers].unsubscribe();
        }
    }


    public getLoggedUser(): Observable<LlaUser> {
        return this.adnBusinessUserO.asObservable();
    }

    public async setLoggedUser(value: LlaUser, token: string) {
        if (value) {
            this.utilSvc.setUid(value.adnUserId);
            this.adnBusinessUserO.next(this.adnBusinessUser);
        } else {
            this.utilSvc.clearUid();
            this.adnBusinessUser = undefined;
            this.adnBusinessUserO.next(undefined);
        }
    }

    public getLanguage(): Observable<string> {
        return this.languageO.asObservable();
    }
    public setLanguage(lang: string) {
        localStorage.setItem('language', lang);
        this.utilSvc.language = lang;
        if (lang != null) {
            this.languageO.next(lang);
        }
    }

    subscribeLlamarkets() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendmarkets].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setLlamarkets(temp);
            }
        );
    }

    unsubscribeLlamarkets() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendmarkets);
        this.setLlamarkets(undefined);
    }

    public getLlamarkets(): Observable<Llamarket[]> {
        return this.llamarketsO.asObservable();
    }

    public setLlamarkets(value: Llamarket[]) {
        if (value && value[0]) {
            for (let market of value) {
                market.datastatus = market.datastatus ? market.datastatus : 'grey';
                market.sipinvitestatus = market.sipinvitestatus ? market.sipinvitestatus : 'grey';
                market.sipresgisterstatus = market.sipresgisterstatus ? market.sipresgisterstatus : 'grey';
                market.trunkstatus = market.trunkstatus ? market.trunkstatus : 'grey';
            }
        }
        this.llamarkets = value;
        if (this.llamarkets) {
            this.opcos = this.llamarkets.map(item => item.opco).filter((value, index, self) => self.indexOf(value) === index)
        }
        this.llamarketsO.next(value);
    }

    subscribeLlamarkets1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendmarkets1].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setLlamarkets1(temp);
            }
        );
    }

    unsubscribeLlamarkets1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendmarkets1);
        this.setLlamarkets1(undefined);
    }

    public getLlamarkets1(): Observable<Llamarket[]> {
        return this.llamarkets1O.asObservable();
    }

    public setLlamarkets1(value: Llamarket[]) {
        this.llamarkets1 = value;
        if (this.llamarkets1) {
            this.opcos1 = this.llamarkets1.map(item => item.opco).filter((value, index, self) => self.indexOf(value) === index)
        }
        this.llamarkets1O.next(value);
    }


    subscribeb2brevenu() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2brevenu].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2brevenu(temp);
            }
        );
    }

    unsubscribeb2brevenu() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2brevenu);
        this.setb2brevenu(undefined);
    }

    public getb2brevenu(): Observable<B2brevenu[]> {
        return this.b2brevenuO.asObservable();
    }

    public setb2brevenu(value: B2brevenu[]) {
        if (value && value[0]) {
            for (let revenu of value) {
                revenu.datastatus = revenu.datastatus ? revenu.datastatus : 'grey';
                revenu.sipinvitestatus = revenu.sipinvitestatus ? revenu.sipinvitestatus : 'grey';
                revenu.sipresgisterstatus = revenu.sipresgisterstatus ? revenu.sipresgisterstatus : 'grey';
                revenu.trunkstatus = revenu.trunkstatus ? revenu.trunkstatus : 'grey';
            }
        }
        this.b2brevenu = value;
        if (this.b2brevenu) {
            this.b2bcustomers = this.b2brevenu.filter((a, i) => this.b2brevenu.findIndex((s) => a.accountName === s.accountName &&
                a.marketName === s.marketName) === i);
        }
        this.b2brevenuO.next(value);
    }

    subscribeb2brevenu1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2brevenu1].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2brevenu1(temp);
            }
        );
    }

    unsubscribeb2brevenu1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2brevenu1);
        this.setb2brevenu1(undefined);
    }

    public getb2brevenu1(): Observable<B2brevenu[]> {
        return this.b2brevenu1O.asObservable();
    }

    public setb2brevenu1(value: B2brevenu[]) {
        this.b2brevenu1 = value;
        if (this.b2brevenu1) {
            this.b2bcustomers1 = this.b2brevenu1.filter((a, i) => this.b2brevenu1.findIndex((s) => a.accountName === s.accountName &&
                a.marketName === s.marketName) === i);
        }
        this.b2brevenu1O.next(value);
    }

    subscribeb2bcustomersites() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2bucsites].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2bcustomersites(temp);
            }
        );
    }

    unsubscribeb2bcustomersites() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2bucsites);
        this.setb2bcustomersites(undefined);
    }

    public getb2bcustomersites(): Observable<B2bcustomersite[]> {
        return this.b2bcustomersitesO.asObservable();
    }

    public setb2bcustomersites(value: B2bcustomersite[]) {
        if (value && value[0]) {
            for (let site of value) {
                site.datastatus = site.datastatus ? site.datastatus : 'grey';
                site.sipinvitestatus = site.sipinvitestatus ? site.sipinvitestatus : 'grey';
                site.sipresgisterstatus = site.sipresgisterstatus ? site.sipresgisterstatus : 'grey';
                site.trunkstatus = site.trunkstatus ? site.trunkstatus : 'grey';
            }
        }
        this.b2bcustomersites = value;
        this.b2bcustomersitesO.next(value);
    }

    subscribeb2bcustomersites1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2bucsites1].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2bcustomersites1(temp);
            }
        );
    }

    unsubscribeb2bcustomersites1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2bucsites1);
        this.setb2bcustomersites1(undefined);
    }

    public getb2bcustomersites1(): Observable<B2bcustomersite[]> {
        return this.b2bcustomersites1O.asObservable();
    }

    public setb2bcustomersites1(value: B2bcustomersite[]) {
        this.b2bcustomersites1 = value;
        this.b2bcustomersites1O.next(value);
    }

    subscribeb2buclines() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2buclines].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2buclines(temp);
            }
        );
    }

    unsubscribeb2buclines() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2bucsites);
        this.setb2buclines(undefined);
    }

    public getb2buclines(): Observable<B2bucline[]> {
        return this.b2buclinesO.asObservable();
    }

    public setb2buclines(value: B2bucline[]) {
        if (value && value[0]) {
            for (let line of value) {
                line.datastatus = line.datastatus ? line.datastatus : 'grey';
                line.sipinvitestatus = line.sipinvitestatus ? line.sipinvitestatus : 'grey';
                line.sipresgisterstatus = line.sipresgisterstatus ? line.sipresgisterstatus : 'grey';
                line.trunkstatus = line.trunkstatus ? line.trunkstatus : 'grey';
            }
        }
        this.b2buclines = value;
        this.b2buclinesO.next(value);
    }

    subscribeb2buclines1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.backendb2buclines1].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null as [];
                this.setb2buclines1(temp);
            }
        );
    }

    unsubscribeb2buclines1() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.backendb2bucsites1);
        this.setb2buclines1(undefined);
    }

    public getb2buclines1(): Observable<B2bucline[]> {
        return this.b2buclines1O.asObservable();
    }

    public setb2buclines1(value: B2bucline[]) {
        this.b2buclines1 = value;
        this.b2buclines1O.next(value);
    }

    public checkValueObject(objectInput, parameterTitle, parameterValue) {
        let found = false;
        for (const key in objectInput) {
            if (objectInput[key]) {
                const valueInput = objectInput[key];
                if (valueInput[parameterTitle] && valueInput[parameterTitle] === parameterValue) {
                    found = true;
                    break;
                }
            }
        }
        return found;
    }

    registerScript(loaded: () => void, url, name): void {
        this.scriptLoadingSvc.registerScript(url, name, loaded);
    }

    goToAnchorLink(anchorLink: string) {
        const x = document.querySelector('#' + anchorLink);
        if (x) {
            x.scrollIntoView();
        }
    }
}
