import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService, } from './utils.service';
import { StoreDbService, } from './firebase-service';

export enum MAILFORMAT {
    TEXT,
    HTML,
}

export interface MailDetailsI {
    from: string;
    fromName: string;
    fromPhone: string;
    to: string;
    toName: string;
    toPhone: string;
    text: string;
    subject: string;
    format: MAILFORMAT;
}

@Injectable({
    providedIn: 'root'
})

export class MailerService {
    constructor(
        public http: HttpClient,
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
    ) {
    }

    sendContact(mail: MailDetailsI) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('fromName', encodeURIComponent(mail.fromName))
                .set('fromEmail', encodeURIComponent(mail.from))
                .set('fromPhone', encodeURIComponent(mail.fromPhone))
                .set('toName', encodeURIComponent(mail.toName))
                .set('toEmail', encodeURIComponent(mail.to))
                .set('subject', encodeURIComponent(mail.subject))
                .set('comments', encodeURIComponent(mail.text));
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/contact', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailExpressPayment(storeEmailAddress: string, customerEmailAddress: string, tabPinCode: string, expressPaymentAmount: number) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(storeEmailAddress))
                .set('customerEmailAddress', encodeURIComponent(customerEmailAddress))
                .set('tabPinCode', tabPinCode)
                .set('expressPaymentAmount', expressPaymentAmount.toString());
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailExpressPayment', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }


}
