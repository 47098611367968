import { NgModule } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { HaversineService } from 'ng2-haversine';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClientModule, } from '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutModule } from './layout/layout.module';

import { environment } from '../environments/environment';

import { GodigitalbModule, UtilsService } from 'godigital-lib';
import { externalUrlProvider } from './services/services.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Page404Component } from './page404/page404.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [AppComponent, Page404Component],
  entryComponents: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GodigitalbModule,
    LayoutModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    IonicModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiUrl,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false
    }),
    FormsModule, ReactiveFormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    StatusBar,
    NavigationBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UtilsService,
    BarcodeScanner,
    DatePipe,
    Geolocation,
    NativeGeocoder,
    HaversineService,
    FirebaseX,
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
