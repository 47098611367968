/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import * as AOS from 'aos';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { UtilsService, KamliApp } from 'godigital-lib';
import { ServicesService, UsersService } from 'godigital-lib';
import { LocalUtilsService } from './services/services.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

declare let what3words: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  private env;
  constructor(
    public router: Router,
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public navigationBar: NavigationBar,
    public mainSvc: ServicesService,
    public localUtilsSvc: LocalUtilsService,
    public usersSvc: UsersService,
    public utilSvc: UtilsService,
    public spinner: NgxSpinnerService,
    public fcm: FirebaseX,
    public geolocation: Geolocation,
    public fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    AOS.init();
    this.localUtilsSvc.language = this.utilSvc.getLanguage() ? this.utilSvc.getLanguage() : 'en';
    this.mainSvc.setLanguage(this.localUtilsSvc.language);

    this.initializeApp();
  }

  async initializeApp() {
    let value2;
    let platform = await this.utilSvc.getPlatformEnv();
    if (platform !== 'dev ' && platform !== 'test ' && platform !== 'prod') {
      platform = undefined;
    }

    this.env = { platform, storeId: 0 };
    this.platform.ready().then(async () => {
      const autoHide = true;
      if (this.platform.is('android')) {
        this.navigationBar.setUp(autoHide);
      }
      if (this.platform.is('cordova')) {
        this.statusBar.hide();
      }

      this.mainSvc.readConfigFile(this.env).then(
        () => {
          this.localUtilsSvc.language = this.utilSvc.getLanguage() ? this.utilSvc.getLanguage() : 'en';
          this.mainSvc.setLanguage(this.localUtilsSvc.language);
          this.env.platform = this.utilSvc.platform;
          this.mainSvc.initBEService(this.env).then(
            data => {
              try {
                if (this.platform.is('cordova')) {
                  this.splashScreen.hide();
                }
                value2 = this.utilSvc.getUid();
              } catch (e) {
                if (this.platform.is('cordova')) {
                  this.splashScreen.hide();
                }
              }
            }
          );
        });
    });
  }
}
